<template lang="pug">
  .user-manage
    .container
      .row.justify-content-md-center
        .col-lg-5
          .text-center.m-4
            img(src="/img/register/status_01.png")
          h1 認証コード入力
          p ご入力いただいたメールアドレスで受信した「認証コード」を入力してください。メールが届かない場合は、迷惑メールフォルダをご確認ください。
          .alert.alert-danger(v-if="message") {{ message }}
          .alert.alert-danger(v-if="errMessage") システムエラーが発生しました。
            div しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが
              router-link(to="/inquiry_form/input") こちら
              |よりをお問い合わせください。
          form.needs-validation(@submit.prevent="exec1" novalidate)
            input.form-control(type="text" placeholder="認証コード" v-model="key" required)
            .invalid-feedback 認証コードを入力してください
            br
            input.btn.btn-primary(type="submit" value="次へ" v-bind:disabled="isDisabled")
          .form-group
          a(href="#" @click.prevent="exec2") 認証コードを再送する
</template>

<script>
import axios from 'axios'

import {
  Config,
  CognitoIdentityCredentials
} from 'aws-sdk'
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute
} from 'amazon-cognito-identity-js'

export default {
  data() {
    return {
      mail: this.$route.params.mail,
      password: this.$route.params.password,
      key: '',
      message: '',
      isDisabled: false,
      errMessage: false,
      checkin_guide_limit: '',
    }
  },
  created() {
    if(!this.mail) {
      this.$router.push('/register_user/send_auth') //ページ移動
      return
    }
  },
  mounted() {
    const poolData = {
      UserPoolId : process.env.VUE_APP_COGNITO_USER_POOL_ID,
      ClientId : process.env.VUE_APP_COGNITO_CLIENT_ID
    }
    this.userPool = new CognitoUserPool(poolData)
  },
  methods: {
    //ダイヤログ表示内容
    asyncDialog() {
      return new Promise((resolve) => {
        this.$bvModal.msgBoxOk('認証コードを再送しました', {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        .then(value => {
          resolve(value)
        })
      })
    },

    exec1(e) {
      this.isDisabled = true

      this.message = ''

      this.errMessage = false

      //スペース削除
      this.key = this.key.trim()

      //エラー表示
      if (e.target.checkValidity() === false) {
        e.target.classList.add('was-validated')
        e.stopPropagation();
        this.isDisabled = false
        return
      }

      // アクティベーション---ここから
      let authenticationData = {
        Username: this.mail,
        Password: this.password
      }
      
      let authenticationDetails = new AuthenticationDetails(authenticationData) //インスタンスの生成

      let userData = {
        Username : this.mail,
        Pool : this.userPool
      }
      
      this.$user.cognito = new CognitoUser(userData)
      
      // アクティベーション処理
      this.$user.cognito.confirmRegistration(this.key, true, (err, result) => {
        if (err) {
          // 処理結果Error
          if(err.code == 'CodeMismatchException' || err.code == 'ExpiredCodeException') {
            this.message = '認証コードが正しくありません。'
          } else if(err.code == 'LimitExceededException' || err.code == 'TooManyRequestsException') {
            this.message = 'しばらく時間をおいてから再度お試しください。'
          } else {
            this.errMessage = true
          }
          this.isDisabled = false
          return
        } else {
          //処理結果OK
          //ログイン処理
          this.$user.cognito.authenticateUser(authenticationDetails, {
            onSuccess: async (result) => {
              // 処理結果Ok
              //Daynamoにユーザー追加
              await this.updUser(result.idToken)

              // 日付取得
              let date = new Date ()

              date.setMonth(date.getMonth() + 1)

              let year = date.getFullYear() 	// 年
              let month = ('0' + (date.getMonth() + 1)).slice(-2)	// 月
              let day = ('0' + date.getDate()).slice(-2) //日

              localStorage.checkin_guide_limit = year + month + day
              localStorage.mail = this.mail
              this.$router.push('/register_user/input') //ページ移動
            },
            onFailure: (err) => {
              // 処理結果Error
              this.errMessage = true
              this.isDisabled = false
              return
            }
          })  
        }
      })
      // アクティベーション---ここまで
    },
    exec2() {
      this.isDisabled = true

      this.message = ''

      let userData = {
        Username : this.mail,
        Pool : this.userPool
      }
      
      this.$user.cognito = new CognitoUser(userData)

      //認証コード再送
      this.$user.cognito.resendConfirmationCode(async (err, result) => {
        if (err) {
          if(err.code == 'LimitExceededException' || err.code == 'TooManyRequestsException') {
            this.message = 'しばらく時間をおいてから再度お試しください。'
          }else {
            this.errMessage = true
          }
          this.isDisabled = false
          return
        }
        await this.asyncDialog()
        this.isDisabled = false
      })
    },
    // ユーザー更新
    updUser(idToken) {
      return new Promise((resolve,reject) => {
        const url = process.env.VUE_APP_API_GATEWAY + '/api-user'
        const params = {
          isVerified: true
        }
        axios.patch(url,params, {
          headers: {
            'Authorization': idToken.jwtToken
          }
        })
        .then(res => {
          resolve()
        })
        .catch(err => {
          console.log(err)
          reject()
        })
      })
    },
  }
}
</script>